<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-end align-items-center"
      style="background: #198754; color: #fff"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-success m-2"
        ><i class="bi bi-x-lg"></i
      ></router-link>
    </div>

    <div
      class="w-100 p-2 pb-5"
      style="overflow: auto; flex-grow: 2"
    >
    <div class="text-center mb-4 d-flex flex-column align-items-center my-3">
        <svg height="100" viewBox="0 0 64 64" width="100" xmlns="http://www.w3.org/2000/svg"><g id="User"><circle cx="32" cy="32" fill="#f8f9fb" r="31"></circle><g fill="#1c7a74"><path d="m56.877 50.4748a31.0647 31.0647 0 0 0 -49.7651-.0156 30.9669 30.9669 0 0 0 49.7651.0156z"></path><circle cx="32" cy="22" r="12"></circle></g></g></svg>

        <strong class="mt-3">{{$store.state.username}}</strong>
      </div>
      <div class="navbar bg-light mb-2">
        <div class="container-fluid">
          <a
            @click.prevent="logout"
            class="navbar-brand"
            href="#"
            >Wyloguj kierowcę</a
          >
        </div>
      </div>
      <div class="navbar bg-light mb-2">
        <div class="container-fluid">
          <a
            @click.prevent="debug"
            class="navbar-brand"
            href="#"
            >Wyślij raport błedów</a
          >
        </div>
      </div>

      <p class="my-4">Data kompilacji: {{ buildDate }}</p>
      <!-- <div class="navbar bg-light mb-2">
                                <div class="container-fluid">
                                    <a class="navbar-brand" href="#">Odłącz aplikację</a>
                                </div>
                            </div> -->
    </div>
    <button @click="fix">fix</button>
    <div class="navbar mb-2 w-100">
      <div class="container-fluid">
        <span
          class="navbar-brand"
          href="#"
          >Twój pojazd: {{ $store.state.kartaDrogowa.location_name }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "AppMenu",
  props: {},
  data: function () {
    return {};
  },
  computed: {
    buildDate: function () {
      return dayjs(document.documentElement.dataset.buildTimestampUtc).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
  },
  methods: {
    logout: function () {
      this.$swal({
        title: "Czy napewno?",
        text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Wyloguj!",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store.dispatch("clearData");
          this.$store.commit("logout");
          this.$router.push("/");
        }
      });
    },
    debug() {
      try {
        const debug = JSON.parse(JSON.stringify(this.$store.state))
        debug.car.cylinderDb=['truncated'];
        debug.car.productDictionary=['truncated'];
        debug.car.clientStorages['truncated'];
        this.$MagicoLog(JSON.stringify(debug), "appStateDebug");
      } catch (e) {
        console.error(e);
      }
      alert('Wysłano raport');
    },
    fix: function () {
      this.$store.commit("car/clearOrderQuene");
      alert("success");
    },
  },
  mounted() {},
};
</script>
