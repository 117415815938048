<template>
    <div class="d-inline-block">
        <CameraScanner @input="submitCode"></CameraScanner>
        <KeyboardScanner @input="submitCode" :listen="true"></KeyboardScanner>
        <div v-if="loading" class="loader-center text-center">
            <div class="spinner-grow" role="status">
            </div>
        </div>

    </div>
</template>
<script>

import KeyboardScanner from '../../components/KeyboardScanner.vue'
import CameraScanner from '../../components/CameraScanner.vue'

export default {
    name: 'StoreCylinderScanner',
    components: {
        CameraScanner,
        KeyboardScanner,
    },
    data: function () {
        return {
            loading: false,
        }
    },
    methods: {
        submitCode: function (code) {
            this.$emit('change');
            let vm = this;
            this.loading = true;
            this.$store.dispatch('car/findCylinder', code).then(cylinder => {
                let cylinder_old = vm.$store.getters['disloading/getCylinder'](cylinder.cylinder_id);
                if (cylinder_old) {
                    vm.$toast.warning("Butla już zeskanowana")
                    window.navigator?.vibrate?.([100, 50, 200, 50]);
                    new Audio('/beep3.mp3').play();
                } else {

                    let cylinder_fit = vm.$store.getters['disloading/getProduct'](cylinder.product_id);
                    if (!cylinder_fit) {
                        vm.$toast.warning("Butla nie pasuje do zamówień");
                        window.navigator?.vibrate?.([100, 50, 200, 50]);
                        new Audio('/beep3.mp3').play();
                    } else {
                        vm.$toast.success("Zeskanowano pomyślnie");
                        vm.$store.commit('disloading/cylinderAdd', cylinder);
                        new Audio('/beep1.mp3').play();
                        window.navigator?.vibrate?.(200);
                    }
                    this.$store.dispatch('disloading/validate');
                }
                vm.loading = false;
            }, error => {
                let lastEnd = vm.$store.getters['car/getCylinderDbQrcode'](code)
                if (lastEnd) {
                    vm.$store.commit('pickup/cylinderAdd', lastEnd);
                    vm.$toast.success("Zeskanowano pomyślnie");
                    new Audio('/beep1.mp3').play();
                    window.navigator?.vibrate?.(200);
                } else {
                    console.log(error);
                    vm.$toast.error("Nie znaleziono butli")
                    new Audio('/beepw.mp3').play();
                    window.navigator?.vibrate?.([100, 50, 200, 50, 100, 50]);
                }
                vm.loading = false;
            })
        }
    }
}
</script>