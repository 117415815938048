<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <button @click="cancelAll" class="btn btn-lg btn-secondary m-2"><i class="bi bi-x-octagon"></i></button>
            <a v-if="signature" type="button" @click.prevent="doSummary"
                class="btn btn-lg btn-success flex-fill m-2"><span v-if="loading"
                    class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Potwierdzam</a>
            <div v-if="!signature" class="flex-fill text-center">
                <h3>Podsumowanie</h3>
            </div>
        </div>
        <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
            <span class="col-9 me-2">Klient:<br> {{ order.title }}</span>
        </div>
        <div class="w-100 p-3 pb-5 flex-grow-1" style="overflow: auto;">
            <div class="mb-2">
                <h2>Towary wydane:</h2>
                <div v-if="takeoutProducts.length == 0">Brak towarów</div>
                <span v-for="item, key in takeoutProducts" :key="key"
                    class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center bg-light">
                    <span class="flex-fill pe-2">{{ item.title }} </span>
                    <span class=" bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center">{{
                        $store.getters['disloading/countCylinder'](item.product_id)
                    }} </span>
                </span>
            </div>

            <div class="mb-4">
                <h2>Towary odebrane:</h2>
                <div v-if="pickupProducts.length == 0">Brak towarów</div>
                <span v-for="item, key in pickupProducts" :key="key"
                    class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center bg-light">
                    <span class="flex-fill pe-2">{{ item.name }} </span>
                    <span class=" bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center">{{
                        $store.getters['pickup/countCylinder'](item.product_id)
                    }} </span>
                </span>
            </div>
            <div class=" mb-4" v-if="complaints.length > 0">
                <h2>Reklamacje</h2>
                <span v-for="item, key in complaints" :key="key"
                    class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center bg-light">
                    <span class="flex-fill pe-2">{{ item.complaint_number }} {{ item.product.name }} </span>
                    <span class=" border text-dark col-3 badge fs-5 d-flex align-items-center justify-content-center">{{
                        item.cylinder ? '#' + item.cylinder.cylinder_qr_code : '---'
                    }}</span>
                </span>
            </div>

            <SignatureGetter v-model="signature"></SignatureGetter>
            <img v-if="signature" :src="signature" class="w-100" style="height:200px; object-fit:contain">

        </div>
        <!-- <div v-if="signature" class="w-100 p-3 pb-1 ">

            <button @click="doSummary" class="w-100 btn btn-success fs-3 p-2">Potwierdzam</button>
        </div> -->
    </div>
</template>
<script>
import SignatureGetter from '../../components/SignatureGetter.vue'
// import CylinderListElement from '../../components/CylinderListElement.vue'

export default {
    name: 'SummaryTab',
    components: {
        SignatureGetter
    },
    data: function () {
        return {
            image: '',
            pickup: null,
            disloading: null,
            signature: null,
            loading:false,
        }
    },
    props: {},
    computed: {
        takeoutProducts: function () {
            return this.$store.state.disloading.order ? this.$store.state.disloading.order.products : [];
        },
        pickupProducts: function () {
            return this.$store.state.pickup.products;
        },
        order: function () {
            let ord = this.$store.state.disloading.order;
            let ord2 = this.$store.state.pickup.order;
            if (!ord && ord2) return ord2;
            if (ord && ord2 && ord.id != ord2.id) alert('SEGMENTATION FAULT');
            return ord;
        },
        complaints: function () {
            return this.$store.state.complaint.complaints;
        }
    },
    methods: {
        doSummary: function () {
            this.loading=true;
            let vm=this;
            this.$swal({
                title: 'Czy napewno?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, potwierdzam!'
            }).then(response => {
                if (response.isConfirmed) {
                    // this.$store.dispatch('pickup/reset');
                    // this.$router.push('/');
                    this.createSummaryRequest();
                    vm.loading=false;
                    if (!this.$store.state.hasInternet) {
                        this.$router.push('/summary/nointernet');
                    } else {
                        this.$router.push('/');
                    }
                }else{
                    vm.loading=false;
                }
            })
        },
        createSummaryRequest: function () {
            let order = JSON.parse(JSON.stringify(this.order));
            order.pickupProducts = [];
            order.pickupCylinders = [];
            order.cylinders = [];
            if (this.$store.state.disloading.order) {
                order.cylinders = this.$store.state.disloading.cylinders;
            }
            if (this.$store.state.pickup.order) {
                order.pickupProducts = this.$store.state.pickup.products;
                order.pickupCylinders = this.$store.state.pickup.cylinders;
            }
            order.signature = this.signature;
            order.complaints = this.$store.state.complaint.complaints;
            order.kierowca_id = this.$store.state.kierowca_id;
            order.location_id = this.$store.state.location_id;

            this.$store.commit('car/addOrderToApprove', order);
            this.$store.dispatch('car/syncOrderApprove', this);
            this.$store.dispatch('pickup/reset');
            this.$store.dispatch('disloading/reset');
            this.$store.dispatch('complaint/reset');
            console.log('request', order);
        },
        changePodpis() {
            this.$store.commit('addSignature', this.image);
        },
        closeOrder() {
            this.$store.dispatch('orderSummary').then(() => {

                // this.$store.dispatch('pickup/takeIn');
                // this.$store.dispatch('disloading/takeOut');
                this.$store.dispatch('closeOrder').then(() => {
                    this.$router.push('summary/end');
                })
            })
        },
        canelOrder() {
            this.$router.push('summary/pytanieprzerwij');
        },
        cancelAll: function () {
            this.$swal({
                title: 'Chcesz przerwać proces?',
                text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, przerywam!'
            }).then(response => {
                if (response.isConfirmed) {
                    this.$store.dispatch('pickup/reset');
                    this.$store.dispatch('disloading/reset');
                    this.$store.dispatch('complaint/reset');
                    this.$router.push('/');
                }
            })
            //
        },
    },
    created() {
        if (!this.$store.state.disloading.order && !this.$store.state.pickup.order) {
            this.$swal('Nie ma czego podsumowywać!').then(() => {
                this.$router.push('/');
            });
        }
        let vm = this;
        if (this.$store.state.disloading.order) {
            this.$store.dispatch('disloading/validate').then(() => {
                console.log('success')
            }, () => {
                this.$swal('Ups..',
                    'Posiadasz błedy walidacji w wydawaniu do klienta. Rozwiąż je!',
                    'error').then(() => {
                        vm.$router.push('/');
                    })
            });
        }
        if (this.$store.state.pickup.order) {
            this.$store.dispatch('pickup/validate').then(() => {
                console.log('success')
            }, () => {
                this.$swal('Ups..',
                    'Posiadasz błedy walidacji w odbiorze od klienta. Rozwiąż je!',
                    'error').then(() => {
                        vm.$router.push('/');
                    })
            });
        }
    },
    mounted() { }
}
</script>