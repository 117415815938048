<template>
  <div class="d-inline-block">
    <CameraScanner @input="submitCode"></CameraScanner>
    <KeyboardScanner
      @input="submitCode"
      :listen="true"
    ></KeyboardScanner>
    <div
      v-if="loading"
      class="loader-center text-center"
    >
      <div
        class="spinner-grow"
        role="status"
      ></div>
    </div>
  </div>
</template>
<script>
/* TODO: Test tworzenia zadania z code */
import KeyboardScanner from "../../components/KeyboardScanner.vue";
import CameraScanner from "../../components/CameraScanner.vue";
import api from "../../api/myApi";

export default {
  name: "StoreCylinderScanner",
  components: {
    CameraScanner,
    KeyboardScanner,
  },
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    submitCode: function (code) {
      this.$emit("change");
      let vm = this;
      this.loading = true;
      api.findCylinder(code).then(
        (response) => {
          console.log(response);
          let cylinder = vm.$store.getters["zaladunek/getCylinder"](response.data.cylinder_id);
          if (cylinder) {
            vm.$toast.warning("Butla już zeskanowana");
            window.navigator?.vibrate?.([100, 50, 200, 50]);
            new Audio("/beep3.mp3").play();
          } else {
            vm.$store.commit("zaladunek/cylinderAdd", response.data);

            let cylinder_fit = vm.$store.getters["zaladunek/getProduct"](response.data.product_id);
            if (!cylinder_fit) {
              vm.$toast.warning("Butla nie pasuje do zamówień");
              window.navigator?.vibrate?.([100, 50, 200, 50]);
              new Audio("/beep3.mp3").play();
              if (this.$store.state.zaladunek.allowEmpty) {
                let product = vm.$store.getters["zaladunek/getProduct"](response.data.product_id);
                if (!product) {
                  let product = vm.$store.getters["car/getProductFromDictionary"](
                    response.data.product_id
                  );
                  if (product) {
                    this.$store.commit("zaladunek/addProduct", product);
                  }
                }
              }
            } else if (response.data.isComplaint) {
              window.navigator?.vibrate?.([100, 50, 200, 50]);
              new Audio("/beep3.mp3").play();
            } else {
              new Audio("/beep1.mp3").play();
              window.navigator?.vibrate?.(200);
              vm.$toast.success("Zeskanowano pomyślnie");
            }
            this.$store.dispatch("zaladunek/validate");
          }
          vm.loading = false;
        },
        (error) => {
          console.log(error);
          vm.$toast.error("Nie znaleziono butli");
          new Audio("/beepw.mp3").play();
          window.navigator?.vibrate?.([100, 50, 200, 50, 100, 50]);
          vm.loading = false;
        }
      );
    },
  },
};
</script>
