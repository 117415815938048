export default {
    store: {},
    valid: true,
    errors: [],
    warnings: [],
    validateQuantity: function (state, getters) {
        let products = state.products;
        console.log(products, 'aaaaaaaa');
        if (products.length == 0) {
            this.pushProductError({ product_id: 0 }, 'noProduct');
        }
        let valid = true;
        // products.forEach(item => {
        //     let count = parseInt(item.quantityFull) + parseInt(item.quantityEmpty);
        //     if (count == 0) {
        //        // this.pushProductError(item, 'noProduct');
        //     }
        // })

        let badCylinder = getters.getBadCylinder;
        console.log('badCylinder', badCylinder)
        badCylinder.forEach(cylinder => {
            this.pushCylinderError(cylinder, 'badCylinderProduct', 'danger');
        })

        return valid;
    },

    validateCylinder: function (state, getters) {
        state.cylinders.forEach(cylinder => {
            let product = getters.getProduct(cylinder.product_id);
            console.log('s', product);
            // if (product) {
            //     let allowEmpty = product.allowEmpty == true ? true : false;
            //     if (cylinder.cylinder_status == 'empty') {
            //         this.pushCylinderError(cylinder, 'empty', (allowEmpty == true ? 'warning' : 'error'));
            //     }
            //     if (cylinder.cylinder_complaint == '1') {
            //         this.pushCylinderError(cylinder, 'complaint', (allowEmpty == true ? 'warning' : 'error'));
            //     }
            // } else {
            //     this.pushCylinderError(cylinder, 'orderFail');
            // }
            if (cylinder.isLegalization == false) {
                this.pushCylinderError(cylinder, 'legalization', 'warning');
            }
            if (cylinder.location_id != state.order.destination.location_id) {
                this.pushCylinderError(cylinder, 'locationFail', 'warning');
            }

        });


    },
    pushProductError: function (product, type, level = 'error') {
        let obj = { cylinder_id: 0, product_id: product.product_id, type: type, info: '' };
        switch (type) {
            case 'quantity':
                obj.info = 'Stan ' + product.title + ' jest niezgodny';
                break;
            case 'emptyCylinder':
                obj.info = 'Produkt ' + product.title + ' zawiera puste butle';
                break;
            case 'noEnoughProductCar':
                obj.info = 'Produkt ' + product.title + ' nie ma tyle na aucie';
                break;
            case 'noProduct':
                obj.info = 'Musisz dodać jakieś produkty żeby je wydać.';
                break;
        }
        if (level == 'warning') {
            this.warnings.push(obj)
        }
        else {
            this.errors.push(obj)
        }
    },
    pushCylinderError: function (cylinder, type, level = 'error') {
        let obj = { cylinder_id: cylinder.cylinder_id, product_id: cylinder.product_id, type: type, info: '' };
        switch (type) {
            case 'empty':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' jest pusta';
                break;
            case 'complaint':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' ma reklamacje';
                break;
            case 'orderFail':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie pasuje do zamówień';
                break;
            case 'locationFail':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie znajduje się u klienta';
                break;
            case 'badCylinderProduct':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie pasuje do żadnego produktu';
                break;
            case 'legalization':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' jest do legalizacji [' + cylinder.cylinder_legalization_date + '] !';
                break;
        }
        if (level == 'warning') {
            this.warnings.push(obj)
        }
        else {
            this.errors.push(obj)
        }
    }
}