<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">
        <div class="w-100 d-flex justify-content-end align-items-center" style="background: #198754; color: #fff;">
            <router-link to="/menu" class="btn btn-lg btn-success m-2"><i class="bi bi-x-lg"></i></router-link>
        </div>
        <div class="w-100 p-2 pb-5 flex-grow-1" style="overflow: auto;  ">
            <h4 class="pb-2">Pojazd: {{ locationName }}<button @click="showLista = showLista ? false : true"
                    class="btn btn-outline-secondary float-end pull-end">Zmień</button>
            </h4>
            <ul class="list-group" v-if="showLista">
                <li v-for="item in storages" class="list-group-item" :key="item.location_id">
                    {{ item.location_name }}
                    <button @click="wybierz(item)" class="btn btn-outline-primary float-end pull-end">Wybierz</button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import myApi from '@/api/myApi';

export default {
    name: 'SettingsView',
    components: {},
    props: {},
    computed: {
        locationName() {
            return this.$store.state.location_name;
        }
    },
    data: function () {
        return {
            storages: [],
            showLista: false,
        }
    },
    methods: {
        wybierz(location) {
            this.$store.dispatch('changeLocation', location);
            // this.$store.commit('setPojazdId', location.magazyn_id);
            // this.$store.commit('setLocationId', location.location_id);
            // this.$store.commit('setLocationName', location.location_name);
            this.showLista = false;
        },
        loadStorages: function () {
            myApi.findLocationStorage().then(response => {
                this.storages = response.data;
            }, (e) => {
                console.log('storages', e)
                // alert('nie można porabć magazynów')
            })
        },
    },
    created() {
        this.loadStorages()
        this.showLista = false;
    },
    mounted() {

    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>