import myaxios from './api/myaxios';
import Vue from 'vue'
import VueAxios from 'vue-axios'
import AppLogin from './AppLogin'
import router from './router'
import "./sass/bootstrap.scss";
import 'dayjs/locale/pl.js';
import store from './store'
//import MagicoVueMonitor from './components/MagicoVueMonitor'
import "bootstrap-icons/font/bootstrap-icons.css";
import 'material-icons/iconfont/material-icons.css';
import './registerServiceWorker'

Vue.use(VueAxios, myaxios)

import MagicoVueMonitor from './MagicoVueMonitor.js'

Vue.use(MagicoVueMonitor, {
    APP_NAME: 'greenapp',
    APP_KEY: 'FtijZYyaE4nnSaRq98tT3KUaptjo9DA62oY2',
    API_URL: 'https://serwer.magico.pl/api/',
    silent: false,
    disablePing: false
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);


Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 2,
  newestOnTop: true,
  closeOnClick: true,
  timeout: 2000,
  hideProgressBar: true,
});

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(AppLogin),
  mounted() { },
  beforeCreate() {
    this.$store.state.authApiUrl = process.env.VUE_APP_OAUTH2_BASE_URL;
    this.$store.state.apiUrl = process.env.VUE_APP_API_URL;
    this.$store.state.hasInternet = true;
    this.$store.commit('initialiseStore');
  }
});

