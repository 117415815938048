<template>
    <div class="w-100 p-3 pb-5 flex-grow-1">
        <div class="w-100 py-2  pb-5 flex-grow-1" style="overflow: auto;  ">
            <div class=" px-2 py-2 text-dark mb-2"
                :class="{ 'bg-lightgreen': i.status == '4', 'bg-light': i.status != '4' }" v-for="i in orders"
                :key="i.id">
                <a href="#" class=" text-dark d-flex justify-content-between align-items-center py-2">
                    <span class="me-2"><B>{{ i.id }}/2022</B> {{ i.title }} <span class="badge bg-success"
                            v-if="i.status == '4'">dostarczone</span>
                        <span class="badge bg-success" v-if="i.status == '3'">załadowane</span>
                        <span class="badge bg-success" v-if="i.status == '2'">do załadunku</span></span>
                </a>
                <ul class="ps-2">
                    <li class="d-flex justify-content-between align-items-center mb-1" v-for="product, key in i.products" :key="key">
                        <span>{{
                            product.title ? product.title :
                                ($store.getters['car/getProduct'](product.product_id) ?
                                    $store.getters['car/getProduct'](product.product_id).name : '--')
                        }}</span>
                        <div><span class="badge bg-secondary" v-if="product.direction == 'in'">odebrana</span></div>
                        <div> <span class="ms-2 badge bg-secondary">
                                {{ product.quantity }}
                            </span></div>
                    </li>
                </ul>
            </div>


        </div>

    </div>
</template>
<script>
export default {
    name: 'alter_orders',
    components: {},
    data: function () {
        return {}
    },
    props: {
        msg: String
    },
    computed: {
        orders: function () {
            return this.$store.getters['car/getOrders'];
        }
    },
    methods: {
        getOrders() {
            this.$store.dispatch('getOrders');
        },
    },
    created() { },
    mounted() {
        this.getOrders();
    }
}
</script>