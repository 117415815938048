<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/pickup"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <StoreCylinderScanner></StoreCylinderScanner>
      <h2 class="flex-fill m-0">Szczegóły towaru</h2>
      <button
        v-if="$store.getters['pickup/countCylinder'](product.product_id) == 0"
        @click="deleteProduct"
        class="btn btn-lg btn-secondary m-2"
      >
        <i class="bi bi-trash"></i>
      </button>
      <button
        v-if="product.quantityEmpty > complaints.filter((item) => item.cylinder_id == 0).length"
        @click="createProductComplaint"
        class="btn btn-lg btn-secondary m-2"
      >
        <i class="bi bi-patch-exclamation"></i>
      </button>
    </div>

    <div class="bg-light p-2 d-flex justify-content-between mb-2">
      <span class="col-9 me-2">{{ product.name }}</span>
      <span
        class="flex-fill badge bg-secondary bg-opacity-50 fs-5 d-flex align-items-center justify-content-center"
      >
        {{ $store.getters["pickup/countCylinder"](product.product_id) }}</span
      >
    </div>

    <div
      class="w-100 p-2 pb-5"
      style="overflow: auto; flex-grow: 2"
    >
      <div class="p-2 d-flex justify-content-center mb-2">
        <button
          @click="showUnsigned = true"
          v-if="!showUnsigned && !isUnsigned"
          class="btn text-light bg-secondary bg-opacity-50 fs-5 d-flex align-items-center justify-content-center"
        >
          + Butla nieoznakowana
        </button>
      </div>

      <!-- <div v-if="showUnsigned || isUnsigned" class="bg-light p-2 mb-2">
                <div class="input-group">
                    <span class="input-group-text">Pełne</span>
                    <input ref="fullInput" :value="product.quantityFull" @input="setQuantityFull" type="tel"
                        aria-label="First name" class="form-control">
                    <button :disabled="product.quantityFull < 1" @click.prevent="addFull(-1)"
                        class="btn btn-secondary btn-lg">-1</button>
                    <button @click.prevent="addFull(1)" class="btn btn-secondary btn-lg">+1</button>
                </div>
            </div> -->

      <div
        v-if="showUnsigned || isUnsigned"
        class="bg-light p-2 mb-2"
      >
        <div class="input-group">
          <span class="input-group-text">Puste</span>
          <input
            ref="emptyInput"
            :value="product.quantityEmpty"
            v-on:keyup.enter="$refs.emptyInput.blur()"
            @input="setQuantityEmpty"
            type="tel"
            aria-label="First name"
            class="form-control"
          />
          <button
            :disabled="product.quantityEmpty < 1"
            @click.prevent="addEmpty(-1)"
            class="btn btn-secondary btn-lg"
          >
            -1
          </button>
          <button
            @click.prevent="addEmpty(1)"
            class="btn btn-secondary btn-lg"
          >
            +1
          </button>
        </div>
      </div>

      <swipe-list
        ref="list"
        class="rounded-0"
        :items="cylinders"
        item-key="id"
        @swipeout:click="showInfoModal"
      >
        <template v-slot="{ item }">
          <!-- item is the corresponding object from the array -->
          <!-- index is clearly the index -->
          <!-- revealLeft is method which toggles the left side -->
          <!-- revealRight is method which toggles the right side -->
          <!-- close is method which closes an opened side -->
          <div
            :class="
              $store.getters['pickup/isLastScannedCylinder'](item.cylinder_id)
                ? 'bg-lightgreen'
                : 'bg-light'
            "
            class="p-2 mb-0"
          >
            <!-- style content how ever you like -->
            <div>#{{ item.cylinder_qr_code }}</div>
            <div
              v-if="
                $store.getters['pickup/getErrors']({ cylinder_id: item.cylinder_id }).length > 0
              "
            >
              <div
                class="text-danger"
                v-for="(error, ke) in $store.getters['pickup/getErrors']({
                  cylinder_id: item.cylinder_id,
                })"
                :key="ke"
              >
                <i class="bi bi-exclamation-diamond-fill pe-1"></i>{{ errorMsg[error.type] }}
              </div>
            </div>
            <div
              v-if="
                $store.getters['pickup/getWarnings']({ cylinder_id: item.cylinder_id }).length > 0
              "
            >
              <div
                class="text-warning"
                v-for="(warning, ke) in $store.getters['pickup/getWarnings']({
                  cylinder_id: item.cylinder_id,
                })"
                :key="ke"
              >
                <i class="bi bi-exclamation-diamond-fill pe-1"></i>{{ errorMsg[warning.type] }}
              </div>
            </div>
            <div
              v-if="$store.getters['complaint/getByCylinderId'](item.cylinder_id)"
              class="text-primary"
            >
              <i class="bi bi-patch-exclamation-fill pe-1"></i>Zgłosiłeś reklamacje do tej butli
            </div>
          </div>
        </template>
        <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
        <!-- remove if you dont wanna have left swipe side  -->

        <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
        <!-- remove if you dont wanna have right swipe side  -->
        <template v-slot:left="{ item }">
          <button
            @click="createCylinderComplaint(item)"
            class="btn btn-outline-warning rounded-0 align-items-center d-flex"
          >
            REKLAMACJA
          </button>
        </template>
        <template v-slot:right="{ item }">
          <button
            @click="deleteCylinder(item)"
            class="btn btn-outline-danger rounded-0"
          >
            USUŃ
          </button>
        </template>
        <template v-slot:empty>
          <div></div>
        </template>
      </swipe-list>
      <div
        class="my-4"
        v-if="complaints.length > 0"
      >
        <h2>Reklamacje</h2>
        <router-link
          :to="'/complaint/' + item.complaint_number + '?back=1'"
          v-for="(item, key) in complaints"
          :key="key"
          class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center bg-light"
        >
          <span class="flex-fill pe-2">Reklamacja nr {{ item.complaint_number }} </span>
          <span
            class="border text-dark col-3 badge fs-5 d-flex align-items-center justify-content-center"
            >{{ item.cylinder ? "#" + item.cylinder.cylinder_qr_code : "---" }}</span
          >
        </router-link>
      </div>
    </div>

    <CylinderModal
      errors-store="zaladunek"
      v-model="showItem"
    ></CylinderModal>
  </div>
</template>
<script>
import StoreCylinderScanner from "@/components/pickup/StoreCylinderScanner.vue";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
import { SwipeList } from "vue-swipe-actions";
import CylinderModal from "../../components/CylinderModal.vue";
import dictionary from "@/helpers/dictionary";
export default {
  name: "DisloadingProduct",
  computed: {
    product: function () {
      return this.$store.getters["pickup/getProduct"](this.$route.params.id);
    },
    cylinders: function () {
      return this.$store.getters["pickup/getProductCylinder"](this.$route.params.id);
    },
    isUnsigned: function () {
      return this.product.quantityEmpty || this.product.quantityFull;
    },
    errorMsg: function () {
      return dictionary.errorMsgCylinderPickup;
    },
    complaints: function () {
      return this.$store.getters["complaint/getAllByProductId"](this.$route.params.id);
    },
  },
  data: function () {
    return {
      showItem: null,
      showUnsigned: false,
    };
  },
  methods: {
    showInfoModal: function (item) {
      this.showItem = item;
    },
    addEmpty: function (c) {
      this.$refs.emptyInput.value = parseInt(this.$refs.emptyInput.value) + parseInt(c);
      this.setQuantityEmpty();
    },
    addFull: function (c) {
      this.$refs.fullInput.value = parseInt(this.$refs.fullInput.value) + parseInt(c);
      this.setQuantityFull();
    },
    setQuantityFull: function () {
      if (this.$refs.fullInput.value < 0) this.$refs.fullInput.value = 0;
      this.$store.commit("pickup/setQuantityFull", {
        product_id: this.product.product_id,
        value: this.$refs.fullInput.value,
      });
    },
    setQuantityEmpty: function () {
      if (this.$refs.emptyInput.value < 0) this.$refs.emptyInput.value = 0;
      let val = parseInt(this.$refs.emptyInput.value);
      if (val < this.complaints.filter((item) => item.cylinder_id == 0).length) {
        this.$refs.emptyInput.value = this.product.quantityEmpty;
        this.$swal({
          title: "Posiadasz reklamacje!",
          text: "Posiadasz dodaną reklamację do nieznakowanych butli. Nie możesz zmniejszyć ich ilości!",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Wróć",
          confirmButtonText: "Ok",
        });
      } else {
        this.$store.commit("pickup/setQuantityEmpty", {
          product_id: this.product.product_id,
          value: this.$refs.emptyInput.value,
        });
      }
    },
    deleteCylinder: function (item) {
      this.$store.commit("pickup/cylinderDel", item);
    },
    createCylinderComplaint: function (cylinder) {
      this.$store
        .dispatch("complaint/createComplaint", {
          product: this.product,
          cylinder: cylinder,
          order: this.$store.state.pickup.order,
        })
        .then(() => {
          this.$router.push("/complaint/create");
        });
    },
    createProductComplaint: function () {
      this.$store
        .dispatch("complaint/createComplaint", {
          product: this.product,
          cylinder: null,
          order: this.$store.state.pickup.order,
        })
        .then(() => {
          this.$router.push("/complaint/create");
        });
    },
    deleteProduct: function () {
      this.$swal({
        title: "Chcesz usunąć produkt?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak, usuwam!",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store.commit("pickup/productDel", this.product);
          this.$router.push("/pickup");
        }
      });
    },
  },
  components: {
    StoreCylinderScanner,
    SwipeList,
    CylinderModal,
  },
};
</script>
<style>
.swipeout {
  border-radius: 0px !important;
  box-shadow: 0 0 0 0;
}
</style>
