import api from '../api/myApi';
import loadingValidator from '@/helpers/loadingValidator';

export default {
    namespaced: true,
    state: {
        cylinders: [],
        products: [],
        orders: [],
        locationFrom: null,
        valid: false,
        error: [],
        allowEmpty: false,
        warnings: [],
        lastScanned: {},
    },
    getters: {
        getProducts: function (state) {
            return state.products;
        },
        getProduct: (state) => (id) => {
            return state.products.find(item => item.product_id == id);
        },
        getCylinder: (state) => (id) => {
            return state.cylinders.find(item => item.cylinder_id == id);
        },
        getProductCylinder: (state) => (id) => {
            return state.cylinders.filter(item => item.product_id == id);
        },
        isLastScannedProduct: (state) => (id) => {
            return state.lastScanned.product_id == id;
        },
        isLastScannedCylinder: (state) => (id) => {
            return state.lastScanned.cylinder_id == id;
        },
        getBadCylinder: (state) => {
            let ids = state.products.map(item => {
                return item.product_id;
            })
            return state.cylinders.filter(item => !ids.includes(item.product_id));
        },
        countCylinder: (state) => (id) => {
            let cylinders_count = state.cylinders.filter(item => item.product_id == id).length;
            let product = state.products.find(item => item.product_id == id);
            return parseInt(cylinders_count) + parseInt(product.quantityEmpty) + parseInt(product.quantityFull);
        },
        isAllCylindersFull: (state) => (id) => {
            let cylinders_count = state.cylinders.filter(item => (item.product_id == id && item.cylinder_status == 'empty')).length;
            return cylinders_count == 0;
        },
        getFullData: (state) => {
            return {
                cylinders: state.cylinders,
                products: state.products,
                order: state.orders,
                locationFromId: state.locationFrom.location_id,
            }
        },
        getErrors: (state) => (data) => {
            if (data.cylinder_id) {
                return state.errors.filter(item => item.cylinder_id == data.cylinder_id);
            }
            if (data.product_id) {
                return state.errors.filter(item => item.product_id == data.product_id);
            }
            return state.errors;
        },
        getWarnings: (state) => (data) => {
            if (data.cylinder_id) {
                return state.warnings.filter(item => item.cylinder_id == data.cylinder_id);
            }
            if (data.product_id) {
                return state.warnings.filter(item => item.product_id == data.product_id);
            }
            return state.warnings;
        },
        countAllCylinders: (state) => {
            let count = state.cylinders.length;
            state.products.forEach(product => {
                count += parseInt(product.quantityEmpty) + parseInt(product.quantityFull);
            })
            return count;
        },

    },
    mutations: {
        reset(state) {
            state.orders = [];
            state.products = [];
            state.cylinders = [];
            state.lastScanned = {};
            state.locationFrom = null;
        },
        setAllowEmpty(state, data) {
            state.allowEmpty = data;
        },
        cylinderAdd(state, data) {
            state.lastScanned = data;
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index === -1) { state.cylinders.push(data); }
        },
        cylinderFit(state, data) {
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index === -1) { state.cylinders.push(data); }
        },
        cylinderReset(state) {
            state.cylinders = [];
        },
        cylinderDel(state, data) {
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index !== -1) { state.cylinders.splice(index, 1); }
        },
        setOrders: function (state, data) {
            state.orders = data;
            console.log('set orders', data)
            // this.$store.commit('greenap.orders', JSON.stringify(state.orders));
        },
        setLocationFrom(state, id) {
            state.locationFrom = id;
        },
        setCylinders: function (state, data) {
            state.cylinders = data;
            // this.$store.commit('greenap.cylinders', JSON.stringify(state.cylinders));
        },
        setProducts: function (state, data) {
            state.products.forEach(item => item._updated = false);
            data.forEach(item => {
                let lastItem = state.products.find(it => it.product_id == item.product_id);

                if (lastItem) {
                    lastItem.quantity = item.quantity;
                    lastItem._updated = true;
                } else {
                    item._local = {}
                    item.quantityFull = 0;
                    item.quantityEmpty = 0;
                    item._updated = true;
                    state.products.push(item);
                }
            })
            state.products = state.products.filter(item => (item._updated == true || item._ownLoading == true));
        },
        setQuantityFull: function (state, data) {
            let lastItem = state.products.find(it => it.product_id == data.product_id);
            if (lastItem) lastItem.quantityFull = parseInt(data.value) >= 0 ? parseInt(data.value) : 0;
        },
        setQuantityEmpty: function (state, data) {
            let lastItem = state.products.find(it => it.product_id == data.product_id);
            if (lastItem) lastItem.quantityEmpty = parseInt(data.value) >= 0 ? parseInt(data.value) : 0;
        },
        productDel(state, data) {
            const index = state.products.findIndex(object => object.product_id === data.product_id);
            if (index !== -1) { state.products.splice(index, 1); }
        },
        addProduct: function (state, item) {
            console.log('add', item);
            if (!state.products.find(it => it.product_id == item.product_id)) {
                item.quantityEmpty = 0;
                item.quantityFull = 0;
                item.title = item.name;
                item._ownLoading = true;
                item.allowEmpty = true;
                state.products.push(JSON.parse(JSON.stringify(item)));
            }
        },
   

    },
    actions: {
        syncOrders({ state, commit, rootState }) {
            api.getTakein(rootState.location_id).then(response => {
                console.log(state);
                //1100commit('setCylinders', response.data.data.cylinders);
                commit('setOrders', response.data.data.orders);
                commit('setProducts', response.data.data.products);
                commit('setAllowEmpty', response.data.data.allowEmpty);
            }, e => {
                console.log('orders', e)
            });
        },
        reset({ commit }) {
            commit('reset')
        },
        validate({ state, getters }) {
            return new Promise((resolve, reject) => {
                loadingValidator.errors = [];
                loadingValidator.warnings = [];

                loadingValidator.validateQuantity(state, getters);
                loadingValidator.validateCylinder(state, getters);
                state.valid = loadingValidator.errors.length == 0;
                state.errors = loadingValidator.errors;
                state.warnings = loadingValidator.warnings;
                if (state.valid) {
                    resolve();
                } else {
                    reject(state.errors);
                }
            })
        }

    }
}