
export default {
    namespaced: true,
    state: {
        complaints: [],
        createComplaint: null,
        lastComplaint: 0,
    },
    mutations: {

        setCreateComplaint(state, data) {
            state.createComplaint = data;
        },
        addComplaint(state, data) {
            state.complaints.push(data);
        },
        incrementNumber(state) {
            state.lastComplaint++;
        },
        resetCreate(state) {
            state.createComplaint = null;
        },
        complaintDel(state, data) {
            const index = state.complaints.findIndex(object => object.complaint_number === data.complaint_number);
            if (index !== -1) { state.complaints.splice(index, 1); }
        },
        reset(state) {
            state.complaints = [];
        },
        setLastNumber({ state, rootState }, data) {
            let kid = rootState.kierowca_id.toString();
            state.lastComplaint = parseInt(data.substring(kid.length));
        },
    },
    getters: {
        getByCylinderId: (state) => (id) => {
            return state.complaints.find(item => item.cylinder_id == id);
        },
        getAllByProductId: (state) => (id) => {
            return state.complaints.filter(item => item.product_id == id);
        },
        getByComplaintNumber: (state) => (id) => {
            return state.complaints.find(item => item.complaint_number == id);
        },
    },
    actions: {
        setLastNumber({ state, rootState }, data) {
            if (!data) return;
            let kid = rootState.kierowca_id.toString();
            state.lastComplaint = parseInt(data.substring(kid.length));
        },
        createComplaint({ getters, commit }, data) {
            return new Promise((resolve, reject) => {
                if (data.cylinder && getters['getByCylinderId'](data.cylinder.cylinder_id)) {
                    reject('Bulta już posiada reklamacje!');
                }
                let obj = {
                    complaint_number: '0',
                    cylinder: data.cylinder,
                    product: data.product,
                    order: data.order,
                    cylinder_id: data.cylinder ? data.cylinder.cylinder_id : 0,
                    product_id: data.product ? data.product.product_id : 0,
                    checkboxes: [],
                    description: '',
                }
                commit('setCreateComplaint', obj);
                resolve(obj.complaint_number);
            })
        },
        approveComplaint({ commit, rootState, state }, data) {
            return new Promise((resolve) => {
                state.createComplaint.checkboxes = data.checkboxes;
                state.createComplaint.description = data.description;
                state.createComplaint.kierowca_id = rootState.kierowca_id;
                state.createComplaint.complaint_number = rootState.kierowca_id.toString() + state.lastComplaint.toString().padStart(6 - rootState.kierowca_id.toString().length, '0');
                let number = state.createComplaint.complaint_number;
                commit('addComplaint', state.createComplaint);
                commit('incrementNumber');
                commit('resetCreate');
                resolve(number);
            })
        },
        reset: function ({ commit }) {
            commit('reset');
        },

    }
}