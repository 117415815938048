<template>
  <div class="flex-fill m-2">
    <button
      @click="openModal"
      class="btn btn-lg btn-danger w-100"
    >
      Błędy ({{ $store.state.zaladunek.errors.length }})
    </button>
    <MagicoModal
      title="Błędy"
      v-model="modal"
    >
      <div class="p-2">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="(item, key) in $store.state.zaladunek.errors"
            :key="key"
          >
            {{ item.info }}
          </li>
        </ul>
      </div>
    </MagicoModal>
  </div>
</template>
<script>
import MagicoModal from "../MagicoModal.vue";

export default {
  name: "ValidationErrorsModal",
  components: {
    MagicoModal,
  },
  data: function () {
    return {
      modal: false,
    };
  },
  methods: {
    openModal: function () {
      this.modal = true;
      try {
        this.$MagicoLog(
          JSON.stringify({
            errors: this.$store.state.zaladunek.errors,
            warnings: this.$store.state.zaladunek.errors,
            products: this.$store.state.zaladunek.products,
            cylinders: this.$store.state.zaladunek.cylinders,
          }),
          "cylinderLoadingErrors"
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
