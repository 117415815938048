import disloadingValidator from '@/helpers/disloadingValidator';

export default {
    namespaced: true,
    state: {
        cylinders: [],
        order: null,
        lastScanned: {},
        errors: [],
        warnings: [],
        valid: true,

    },
    mutations: {

        cylinderAdd(state, data) {
            state.lastScanned = data;
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index === -1) { state.cylinders.push(data); }
        },
        cylinderReset(state) {
            state.cylinders = [];
        },
        cylinderDel(state, data) {
            console.log('cylinderdel', data)
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index !== -1) { state.cylinders.splice(index, 1); }
        },
        start(state, data) {
            state.start = data
        },
        setOrder(state, data) {
            data.products.forEach(item => {
                item.quantityEmpty = 0;
                item.quantityFull = 0;
            })
            state.order = JSON.parse(JSON.stringify(data));

        },
        setQuantityFull: function (state, data) {
            let lastItem = state.order.products.find(it => it.product_id == data.product_id);
            if (lastItem) lastItem.quantityFull = parseInt(data.value) >=0? parseInt(data.value) : 0;
        },
        setQuantityEmpty: function (state, data) {
            let lastItem = state.order.products.find(it => it.product_id == data.product_id);
            if (lastItem) lastItem.quantityEmpty = parseInt(data.value) >=0? parseInt(data.value) : 0;
        },
        reset(state) {
            state.order = null;
            state.cylinders = [];
            state.lastScanned = {};
            state.errors = [];
            state.warnings = [];
        }
    },
    getters: {
        isLastScannedProduct: (state) => (id) => {
            return state.lastScanned.product_id == id;
        },
        isLastScannedCylinder: (state) => (id) => {
            return state.lastScanned.cylinder_id == id;
        },
        getCylinder: (state) => (id) => {
            return state.cylinders.find(item => item.cylinder_id == id);
        },
        getProduct: (state) => (id) => {
            return state.order.products.find(item => item.product_id == id);
        },
        getProductCylinder: (state) => (id) => {
            return state.cylinders.filter(item => item.product_id == id);
        },
        getFullData: (state) => {
            return {
                cylinders: state.cylinders,
                products: state.products,
                order: state.orders,
                locationFromId: state.locationFrom.location_id,
            }
        },
        isAllCylindersFull: (state) => (id) => {
            let cylinders_count = state.cylinders.filter(item => (item.product_id == id && item.cylinder_status == 'empty')).length;
            return cylinders_count == 0;
        },
        getErrors: (state) => (data) => {
            if (data.cylinder_id) {
                return state.errors.filter(item => item.cylinder_id == data.cylinder_id);
            }
            if (data.product_id) {
                return state.errors.filter(item => item.product_id == data.product_id);
            }
            return state.errors;
        },
        getWarnings: (state) => (data) => {
            if (data.cylinder_id) {
                return state.warnings.filter(item => item.cylinder_id == data.cylinder_id);
            }
            if (data.product_id) {
                return state.warnings.filter(item => item.product_id == data.product_id);
            }
            return state.warnings;
        },
        getBadCylinder: (state) => {
            let ids = state.order.products.map(item => {
                return item.product_id;
            })
            return state.cylinders.filter(item => !ids.includes(item.product_id));
        },
        countCylinder: (state) => (id) => {
            let cylinders_count = state.cylinders.filter(item => item.product_id == id).length;
            let product = state.order.products.find(item => item.product_id == id);
            return parseInt(cylinders_count) + parseInt(product.quantityEmpty) + parseInt(product.quantityFull);
        },
        countAllCylinders: (state) => {
            let count = state.cylinders.length;
            state.order.products.forEach(product => {
                count += parseInt(product.quantityEmpty) + parseInt(product.quantityFull);
            })
            return count;
        },
    },
    actions: {
        reset: function ({ commit }) {
            commit('reset');
        },
        validate({ state, getters, rootState, rootGetters }) {
            return new Promise((resolve, reject) => {
                disloadingValidator.errors = [];
                disloadingValidator.warnings = [];

                disloadingValidator.validateQuantity(state, getters, rootGetters);
                disloadingValidator.validateCylinder(state, getters, rootState);
                state.valid = disloadingValidator.errors.length == 0;
                state.errors = disloadingValidator.errors;
                state.warnings = disloadingValidator.warnings;
                if (state.valid) {
                    resolve();
                } else {
                    reject(state.errors);
                }
            })
        }
    }
}