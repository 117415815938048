<template>
    <div>
        <div class="modal fade" :ref="'modal' + key" :id="'modal' + key" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div v-if="value" class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Informacje o butli {{
                            value.cylinder_qr_code
                        }}
                        </h5>
                        <button type="button" class="btn-close" @click="hide()" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <CylinderInfo :cylinder="value"></CylinderInfo>
                        <div class="mb-2"
                            v-if="errorsStore && $store.getters[errorsStore + '/getErrors']({ cylinder_id: value.cylinder_id }).length > 0">
                            <h3 class="text-danger">Błędy</h3>
                            <ul class="list-group">
                                <li class="list-group-item"
                                    v-for="item, key in $store.getters['zaladunek/getErrors']({ cylinder_id: value.cylinder_id })"
                                    :key="key">{{
                                        item.info
                                    }}</li>
                            </ul>


                        </div>
                        <h3>Ostanie 5 wpisów dziennika</h3>
                        <CylinderLogs :cylinder_id="value.cylinder_id" limit="5"></CylinderLogs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import CylinderLogs from './CylinderLogs.vue';
import CylinderInfo from './CylinderInfo.vue';
export default {
    name: 'CylinderModal',
    components: {
        CylinderLogs,
        CylinderInfo
    },
    props: {
        value: Object,
        errorsStore: String,
    },
    data: function () {
        return {
            statusLabel: { 'full': 'Pełna', 'empty': 'Pusta' },
            code: '',
            key: (Math.random() + 1).toString(36).substring(7),
            listener: null,
            data: {},
            modal: null,
        }
    },
    computed: {

    },

    methods: {
        hide: function () {
            this.modal.hide();
            this.$emit('input', null);
        },
        showModal: function () {

            console.log('ec');
        }

    },
    watch: {
        value: function () {
            if (this.value && this.value.cylinder_qr_code) {
                this.modal.show();
            } else {
                this.modal.hide();
            }
        },
    },
    mounted() {
        this.modal = new Modal(this.$refs['modal' + this.key])
        document.getElementsByTagName('body')[0].appendChild(this.$refs['modal' + this.key])
        let vm = this;
        this.$refs['modal' + this.key].addEventListener('hidden.bs.modal', function () {
            vm.$emit('input', null);
            vm.modal.hide();
        })

        if (this.value && this.value.cylinder_qr_code) {
            this.modal.show();
        }

    },
    beforeDestroy: function () {
        document.getElementById('modal' + this.key).remove();
    }
}
</script>