<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-house"></i
      ></router-link>
      <h2 class="flex-fill m-0">Wyszukaj butle</h2>
    </div>

    <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
      <span class="col-12">Wprowadź numer lub zeskanuj kod QR</span>
    </div>

    <div
      class="container p-0"
      style="overflow: auto; flex-grow: 2"
    >
      <div class="row mx-0 mt-1">
        <div class="col-12 mb-1">
          <div class="input-group mb-0">
            <input
              v-model="pin"
              type="text"
              class="form-control fs-3"
              placeholder="Podaj kod butli"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
            />
            <button
              class="btn btn-outline-secondary btn-lg"
              type="button"
              id="button-addon2"
            >
              <i class="bi bi-qr-code-scan"></i>
            </button>
          </div>
        </div>
        <div
          v-for="i in 9"
          class="col-4 mb-2"
          :key="i"
        >
          <button
            @click.prevent="pin = pin + i"
            class="btn btn-primary w-100 fs-1 py-2 btn-number"
          >
            {{ i }}
          </button>
        </div>
        <div class="col-4 mb-3">
          <button
            @click.prevent="pin = pin + 0"
            class="btn btn-primary w-100 fs-1 py-2 btn-number"
          >
            0
          </button>
        </div>
        <div class="col-4 mb-3">
          <button
            @click.prevent="pin = pin + 'W'"
            class="btn btn-primary w-100 fs-1 py-2 btn-number"
          >
            W
          </button>
        </div>
        <div class="col-4 mb-3">
          <button
            @click.prevent="pin = pin + 'Z'"
            class="btn btn-primary w-100 fs-1 py-2 btn-number"
          >
            Z
          </button>
        </div>
        <div class="col-4 mb-3">
          <button
            @click="pin = ''"
            class="btn btn-danger w-100 fs-1 py-2"
          >
            <span
              class="material-icons"
              style="position: relative; top: 7px; font-size: 40px"
            >
              clear
            </span>
          </button>
        </div>
        <div class="col-8 mb-3">
          <button
            @click.prevent="modalOk()"
            class="btn btn-success w-100 fs-1 py-2"
          >
            <span
              class="material-icons"
              style="position: relative; top: 7px; font-size: 40px"
            >
              check_circle_outline
            </span>
          </button>
        </div>
      </div>
    </div>
    <KeyboardScanner
      :listen="true"
      @input="searchCylinder"
    ></KeyboardScanner>
  </div>
</template>

<script>
import KeyboardScanner from "@/components/KeyboardScanner.vue";

export default {
  name: "CylinderSearch",
  components: { KeyboardScanner },
  data: function () {
    return {
      pin: "",
    };
  },
  methods: {
    modalOk: function () {
      if (this.pin) {
        this.$router.push("/cylinder/qrcode/" + this.pin);
      }
    },
    searchCylinder: function (code) {
      this.$router.push("/cylinder/qrcode/" + code);
    },
  },
};
</script>

<style>
.btn-number {
  width: 60px;
  height: 60px;
}
</style>
