<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/disloading"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <StoreCylinderScanner></StoreCylinderScanner>
      <h2 class="flex-fill m-0">Szczegóły towaru</h2>
    </div>

    <div class="bg-light p-2 d-flex justify-content-between mb-2">
      <span class="col-9 me-2">{{ product.title }}</span>
      <span
        class="flex-fill badge bg-secondary bg-opacity-50 fs-5 d-flex align-items-center justify-content-center"
      >
        {{ $store.getters["disloading/countCylinder"](product.product_id) }} /
        {{ product.quantity }}</span
      >
    </div>

    <div
      class="w-100 p-2 pb-5"
      style="overflow: auto; flex-grow: 2"
    >
      <div class="p-2 d-flex justify-content-center mb-2">
        <button
          @click="showUnsigned = true"
          v-if="!showUnsigned && !isUnsigned"
          class="btn text-light bg-secondary bg-opacity-50 fs-5 d-flex align-items-center justify-content-center"
        >
          + Butla nieoznakowana
        </button>
      </div>

      <div
        v-if="showUnsigned || isUnsigned"
        class="bg-light p-2 mb-2"
      >
        <div class="input-group">
          <span class="input-group-text">Pełne</span>
          <input
            ref="fullInput"
            :value="product.quantityFull"
            v-on:keyup.enter="$refs.fullInput.blur()"
            @input="setQuantityFull"
            type="tel"
            aria-label="First name"
            class="form-control"
          />
          <button
            :disabled="product.quantityFull < 1"
            @click.prevent="addFull(-1)"
            class="btn btn-secondary btn-lg"
          >
            -1
          </button>
          <button
            @click.prevent="addFull(1)"
            class="btn btn-secondary btn-lg"
          >
            +1
          </button>
        </div>
      </div>

      <div
        v-if="product.allowEmpty && (showUnsigned || isUnsigned)"
        class="bg-light p-2 mb-2"
      >
        <div class="input-group">
          <span class="input-group-text">Puste</span>
          <input
            ref="emptyInput"
            :value="product.quantityEmpty"
            @input="setQuantityEmpty"
            type="tel"
            v-on:keyup.enter="$refs.emptyInput.blur()"
            aria-label="First name"
            class="form-control"
          />
          <button
            :disabled="product.quantityEmpty < 1"
            @click.prevent="addEmpty(-1)"
            class="btn btn-secondary btn-lg"
          >
            -1
          </button>
          <button
            @click.prevent="addEmpty(1)"
            class="btn btn-secondary btn-lg"
          >
            +1
          </button>
        </div>
      </div>

      <swipe-list
        ref="list"
        class="rounded-0"
        :items="cylinders"
        item-key="id"
        @swipeout:click="showInfoModal"
      >
        <template v-slot="{ item }">
          <!-- item is the corresponding object from the array -->
          <!-- index is clearly the index -->
          <!-- revealLeft is method which toggles the left side -->
          <!-- revealRight is method which toggles the right side -->
          <!-- close is method which closes an opened side -->
          <div
            :class="
              $store.getters['disloading/isLastScannedCylinder'](item.cylinder_id)
                ? 'bg-lightgreen'
                : 'bg-light'
            "
            class="p-2 mb-0"
          >
            <!-- style content how ever you like -->
            <div>#{{ item.cylinder_qr_code }}</div>
            <div
              v-if="
                $store.getters['disloading/getErrors']({ cylinder_id: item.cylinder_id }).length > 0
              "
            >
              <div
                class="text-danger"
                v-for="(error, ke) in $store.getters['disloading/getErrors']({
                  cylinder_id: item.cylinder_id,
                })"
                :key="ke"
              >
                <i class="bi bi-exclamation-diamond-fill pe-1"></i>{{ errorMsg[error.type] }}
              </div>
            </div>
            <div
              v-if="
                $store.getters['disloading/getWarnings']({ cylinder_id: item.cylinder_id }).length >
                0
              "
            >
              <div
                class="text-warning"
                v-for="(warning, ke) in $store.getters['disloading/getWarnings']({
                  cylinder_id: item.cylinder_id,
                })"
                :key="ke"
              >
                <i class="bi bi-exclamation-diamond-fill pe-1"></i>{{ errorMsg[warning.type] }}
              </div>
            </div>
          </div>
        </template>
        <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
        <!-- remove if you dont wanna have left swipe side  -->

        <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
        <!-- remove if you dont wanna have right swipe side  -->
        <template v-slot:right="{ item }">
          <button
            @click="deleteCylinder(item)"
            class="btn btn-outline-danger rounded-0"
          >
            USUŃ
          </button>
        </template>
        <template v-slot:empty>
          <div></div>
        </template>
      </swipe-list>
    </div>
    <CylinderModal
      errors-store="zaladunek"
      v-model="showItem"
    ></CylinderModal>
  </div>
</template>
<script>
import StoreCylinderScanner from "@/components/disloading/StoreCylinderScanner.vue";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
import { SwipeList } from "vue-swipe-actions";
import CylinderModal from "../../components/CylinderModal.vue";
import dictionary from "@/helpers/dictionary";
export default {
  name: "DisloadingProduct",
  computed: {
    product: function () {
      return this.$store.getters["disloading/getProduct"](this.$route.params.id);
    },
    cylinders: function () {
      return this.$store.getters["disloading/getProductCylinder"](this.$route.params.id);
    },
    isUnsigned: function () {
      return this.product.quantityEmpty || this.product.quantityFull;
    },
    errorMsg: function () {
      return dictionary.errorMsgCylinderDisloading;
    },
  },
  data: function () {
    return {
      showItem: null,
      showUnsigned: false,
    };
  },
  methods: {
    showInfoModal: function (item) {
      this.showItem = item;
    },
    addEmpty: function (c) {
      this.$refs.emptyInput.value = parseInt(this.$refs.emptyInput.value) + parseInt(c);
      this.setQuantityEmpty();
    },
    addFull: function (c) {
      this.$refs.fullInput.value = parseInt(this.$refs.fullInput.value) + parseInt(c);
      this.setQuantityFull();
    },
    setQuantityFull: function () {
      if (this.$refs.fullInput.value < 0) this.$refs.fullInput.value = 0;
      this.$store.commit("disloading/setQuantityFull", {
        product_id: this.product.product_id,
        value: this.$refs.fullInput.value,
      });
    },
    setQuantityEmpty: function () {
      if (this.$refs.emptyInput.value < 0) this.$refs.emptyInput.value = 0;
      this.$store.commit("disloading/setQuantityEmpty", {
        product_id: this.product.product_id,
        value: this.$refs.emptyInput.value,
      });
    },
    deleteCylinder: function (item) {
      this.$store.commit("disloading/cylinderDel", item);
    },
  },
  components: {
    StoreCylinderScanner,
    SwipeList,
    CylinderModal,
  },
};
</script>
<style>
.swipeout {
  border-radius: 0px !important;
  box-shadow: 0 0 0 0;
}
</style>
