import Vue from 'vue';

export default {

    findLocationStorageState(location_id) {
        return Vue.axios.get('greenapp/location/' + location_id + '/storage')
    },
    getLocationStorage() {
        return Vue.axios.get('greenapp/location/storages/auto')
    },
    getCylinderDb() {
        return Vue.axios.get('greenapp/cylinder/minimal')
    },
    findStorages(type, locationFrom = null) {
        return Vue.axios.get('greenapp/location/storages/' + type + (locationFrom ? '?locationFromId=' + locationFrom : ''))
    },
    getProductsDictionary() {
        return Vue.axios.get('greenapp/product')
    },
    getMe: function () {
        return Vue.axios.get('greenapp/me')
    },
    getKierowcaByPin: function (pin) {
        return Vue.axios.post('greenapp/kierowca/pin', { pin: pin });
    },
    getKartaDrogowa: function (kierowca_id) {
        return Vue.axios.get('greenapp/kierowca/' + kierowca_id + '/kartadrogowa');
    },
    findCylinder(qrcode) {
        return Vue.axios.get('greenapp/cylinder/qrcode/' + qrcode)
    },
    getCylinderLogs(cylinder_id, limit = 5) {
        return Vue.axios.get('/greenapp/cylinder/' + cylinder_id + '/log?limit=' + limit)
    },
    getOrders: function (locationId) {
        return Vue.axios.get('greenapp/order/location/' + locationId)
    },
    getTakein: function (locationId) {
        return Vue.axios.get('greenapp/order/location/' + locationId + '/takein')
    },
    doTakein: function (locationId, data) {
        return Vue.axios.post('greenapp/order/location/' + locationId + '/takein', data)
    },
    patchOrder(data) {
        return Vue.axios.post('greenapp/order/summary', data)
    },
    closeOrder(data) {
        return Vue.axios.post('greenapp/order/closeorder', data);
    },
    getCylinderComplaint(cylinder_id) {
        return Vue.axios.get('blueapp/cylinder/' + cylinder_id + '/complaint');
    }
}