<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/storage" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Szczegóły towaru</h2>
        </div>

        <div class="bg-light p-2 d-flex justify-content-between mb-2">
            <span class="col-9 me-2">{{ product.name }}</span>
        </div>

        <div class="w-100 p-2 pb-5" style="overflow: auto; flex-grow: 2;">
            <div class="p-2 d-flex justify-content-center mb-2">
                <button @click="showUnsigned = true" v-if="!showUnsigned && !isUnsigned"
                    class="btn text-light bg-secondary bg-opacity-50 fs-5 d-flex align-items-center justify-content-center">+
                    Butla nieoznakowana</button>
            </div>

            <div class="bg-light p-2 ">
                <div class="input-group">
                    <span class="input-group-text col-7">Pełne nieoznakowane</span>
                    <input disabled ref="fullInput" :value="product.quantityFull" @input="setQuantityFull" type="tel"
                        aria-label="First name" class="form-control">

                </div>
            </div>

            <div class="bg-light p-2 ">
                <div class="input-group">
                    <span class="input-group-text col-7">Puste nieoznakowane</span>
                    <input disabled ref="emptyInput" :value="product.quantityEmpty" @input="setQuantityEmpty" type="tel"
                        aria-label="First name" class="form-control">

                </div>
            </div>

            <div class="bg-light p-2 ">
                <div class="input-group">
                    <span class="input-group-text col-7">Pełne oznakowane</span>
                    <input disabled ref="fullInput" :value="product.cylinderFull" @input="setQuantityFull" type="tel"
                        aria-label="First name" class="form-control">

                </div>
            </div>

            <div class="bg-light p-2 ">
                <div class="input-group">
                    <span class="input-group-text col-7">Puste oznakowane</span>
                    <input disabled ref="emptyInput" :value="product.cylinderEmpty" @input="setQuantityEmpty" type="tel"
                        aria-label="First name" class="form-control">

                </div>
            </div>

            <swipe-list ref="list" class="rounded-0" :items="cylinders" item-key="id" @swipeout:click="showInfoModal">
                <template v-slot="{ item }">
                    <!-- item is the corresponding object from the array -->
                    <!-- index is clearly the index -->
                    <!-- revealLeft is method which toggles the left side -->
                    <!-- revealRight is method which toggles the right side -->
                    <!-- close is method which closes an opened side -->
                    <div :class="$store.getters['zaladunek/isLastScannedCylinder'](item.cylinder_id) ? 'bg-lightgreen' : 'bg-light'"
                        class=" p-2  mb-0 ">
                        <!-- style content how ever you like -->
                        <div>
                            #{{ item.cylinder_qr_code }}
                        </div>
                        <span class=" text-warning" v-if="item.cylinder_status == 'empty'"><i
                                class="bi bi-exclamation-diamond-fill"></i> Butla pusta</span>

                    </div>
                </template>
                <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
                <!-- remove if you dont wanna have left swipe side  -->

                <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
                <!-- remove if you dont wanna have right swipe side  -->

                <template v-slot:empty>
                    <div>

                    </div>
                </template>
            </swipe-list>


        </div>
        <CylinderModal v-model="showItem"></CylinderModal>
    </div>

</template>
<script>
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { SwipeList } from 'vue-swipe-actions';
import CylinderModal from '../../components/CylinderModal.vue'
export default {
    name: "LoadingItem",
    computed: {
        product: function () {
            return this.$store.getters["car/getProduct"](this.$route.params.id);
        },
        cylinders: function () {
            return this.product.cylinders;
        },
        isUnsigned: function () {
            return this.product.quantityEmpty || this.product.quantityFull;
        },

    },
    data: function () {
        return {
            showItem: null,
            showUnsigned: false,
        }
    },
    methods: {
        showInfoModal: function (item) {
            this.showItem = item;
        },
        addEmpty: function (c) {
            this.$refs.emptyInput.value = parseInt(this.$refs.emptyInput.value) + parseInt(c);
            this.setQuantityEmpty();
        },
        addFull: function (c) {
            this.$refs.fullInput.value = parseInt(this.$refs.fullInput.value) + parseInt(c);
            this.setQuantityFull();
        },
        setQuantityFull: function () {
            this.$store.commit("zaladunek/setQuantityFull", { product_id: this.product.product_id, value: this.$refs.fullInput.value });
        },
        setQuantityEmpty: function () {
            this.$store.commit("zaladunek/setQuantityEmpty", { product_id: this.product.product_id, value: this.$refs.emptyInput.value });
        },
        deleteCylinder: function (item) {
            this.$store.commit('zaladunek/cylinderDel', item);
        }
    },
    components: {
        SwipeList,
        CylinderModal
    }
}
</script>
<style>
.swipeout {
    border-radius: 0px !important;
    box-shadow: 0 0 0 0;
}
</style>