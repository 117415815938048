<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between" style="background: #dadada">
            <router-link to="/disloading" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
        </div>





        <div class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-center flex-column justify-content-center text-center"
            style="
            overflow: auto; ">
            <h2 class=" mx-3 text-center mt-4">Czy chcesz odebrać towar od klienta?</h2>
            <a href="#" @click.prevent="confirm" class="btn btn-success btn-lg mt-4 p-4">Tak, odbieram!</a>
            <router-link to="/summary" class="btn btn-outline-secondary btn-lg mt-4">Nie, przejdź do
                podsumowania</router-link>
        </div>





    </div>
</template>
<script>
export default {
    name: 'QuestionStart',
    computed: {
        // order: function () {
        //     return this.$store.getters['car/getOrder'](this.$route.params.id)
        // }
    },
    created() {
        if (this.$store.state.pickup.order) {
            this.$router.push('/pickup');
        }
    },
    methods: {
        confirm: function () {
            let order = this.$store.getters['car/getOrder'](this.$store.state.disloading.order.id);
            this.$store.commit('pickup/setOrder', order);
            this.$router.push('/pickup');
        }
    }
}
</script>