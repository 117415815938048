export default {
    errorMsgCylinderLoading: {
        'empty': 'Butla pusta',
        'locationFail': 'Butla nie jest w tym magazynie',
        'orderFail': 'Butla nie pasuje do zamówienia',
        'badCylinderProduct': 'Butla nie pasuje do żadnego produktu',
        'complaint': 'Butla ma reklamacje',
        'legalization': 'Musi zostać zalegalizowana'
    },
    errorMsgCylinderDisloading: {
        'empty': 'Butla pusta',
        'locationFail': 'Butla nie jest na aucie',
        'orderFail': 'Butla nie pasuje do zamówienia',
        'badCylinderProduct': 'Butla nie pasuje do żadnego produktu',
        'complaint': 'Butla ma reklamacje',
        'legalization': 'Musi zostać zalegalizowana'
    },
    errorMsgCylinderPickup: {
        'empty': 'Butla pusta',
        'locationFail': 'Butla nie jest u tego klienta',
        'orderFail': 'Butla nie pasuje do zamówienia',
        'badCylinderProduct': 'Butla nie pasuje do żadnego produktu',
        'complaint': 'Butla ma reklamacje',
        'legalization': 'Musi zostać zalegalizowana'
    }

}