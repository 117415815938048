<template>
    <div v-if="order" class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <StoreCylinderScanner @change="showError = true"></StoreCylinderScanner>
            <button @click="cancelPickup" class="btn btn-lg btn-secondary m-2"><i class="bi bi-x-octagon"></i></button>
            <!-- <button @click="cancelPickup" class="btn btn-lg btn-secondary m-2"><i class="bi bi-x-octagon"></i></button> -->
            <a v-if="$store.state.pickup.valid || !showError" type="button" @click.prevent="doEnd"
                class="btn btn-lg btn-success flex-fill m-2">Dalej</a>
            <ValidationErrorsModal v-if="!$store.state.pickup.valid && showError" type="button"></ValidationErrorsModal>
        </div>
        <div @click="showFullCLientName = !showFullCLientName"
            class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
            <span class="col-9   mw-100" :class="{ 'text-truncate': !showFullCLientName }">Wydawanie do:<br> {{
                order.title
            }}</span>

            <div class="col-3 ">
                <span class="bg-secondary me-2 ms-0  h-100 badge fs-5 d-flex align-items-center justify-content-center ">{{
                    $store.getters['pickup/countAllCylinders'] }}</span>
            </div>
        </div>

        <div class="w-100 p-2 pb-5 flex-grow-1" style="overflow: auto;">
            <i v-if="items.length == 0">Zeskanuj butlę lub dodaj towar</i>
            <router-link :to="'/pickup/product/' + item.product_id" v-for="item, key in items" :key="key"
                :class="$store.getters['pickup/isLastScannedProduct'](item.product_id) ? 'bg-lightgreen' : 'bg-light'"
                class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center">
                <span class="flex-fill pe-2">{{ item.name }} </span>

                <span
                    :class="{ 'bg-danger': $store.getters['pickup/getErrors']({ product_id: item.product_id }).length > 0, 'bg-warning': $store.getters['pickup/getWarnings']({ product_id: item.product_id }).length > 0 }"
                    class=" bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center">{{
                        $store.getters['pickup/countCylinder'](item.product_id)
                    }} </span>
            </router-link>

            <router-link v-if="badCylinders.length > 0" :to="'/pickup/badcylinder'"
                class="bg-light p-2 text-dark d-flex justify-content-between mb-2 align-items-center">
                <span class="flex-fill pe-2 text-danger">Butle nie pasujące</span>

                <span class=" bg-danger col-3 badge fs-5 d-flex align-items-center justify-content-center">
                    {{ badCylinders.length }}</span>
            </router-link>

        </div>
        <!-- Magazyn żródłowy: {{ locationFrom.location_name }} -->
        <router-link to="/pickup/selectproduct"
            style="width: 50px; height: 50px; background: #dadada; border-radius: 5px; font-weight: bold; position: fixed; right: 10px; bottom: 10px;z-index:1000"
            class="d-flex justify-content-center align-items-center fs-1 text-dark">
            <i class="bi bi-plus"></i>
        </router-link>
        <!-- {{ $store.state.zaladunek.cylinders.length }}/ {{ iloscBulti }}< -->
    </div>
</template>
<script>
// import myApi from '@/api/myApi'

import StoreCylinderScanner from '@/components/pickup/StoreCylinderScanner.vue';
// import api from '@/api/myApi';
import ValidationErrorsModal from '@/components/pickup/ValidationErrorsModal.vue';
// import CylinderListElement from '../components/CylinderListElement.vue'

export default {
    name: 'PikcupView',
    components: {
        StoreCylinderScanner,
        ValidationErrorsModal
    },
    props: {
        msg: String,
    },
    data: function () {
        return {
            submitCode: '',
            location_id: 0,
            orders: [],
            iloscBulti: 0,
            iloscBultiinOrders: 0,
            skantext: '',
            loadTowary: [],
            cylinders: [], //zeskanowowane butle
            inne: [], //produkty ktore sa złe
            pojazd_id: 0,
            listaQrcodowDoOPrzyjecia: [],
            showError: false,
            showFullCLientName: false,

        }
    },
    computed: {
        order: function () {
            return this.$store.state.pickup.order;
        },
        items: function () {
            return this.$store.state.pickup.products;
        },
        // locationFrom: function () {
        //     return this.$store.state.zaladunek.locationFrom ? this.$store.state.zaladunek.locationFrom : {};
        // },
        badCylinders: function () {
            return this.$store.getters["pickup/getBadCylinder"];
        },
    },
    methods: {
        doEnd: function () {
            this.showError = true;
            let warnings = this.$store.state.pickup.warnings;
            if (warnings.length > 0) {
                this.$swal({
                    title: 'Uwaga!',
                    html: warnings.length > 0 ? '' + Array.from(warnings, it => it.info).join('<br> ') : '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Wróć',
                    confirmButtonText: 'Dalej!'
                }).then(this.validateAndGo);
            } else {
                this.validateAndGo({ isConfirmed: true });
            }

        },
        validateAndGo: function (response) {
            if (response.isConfirmed) {
                let vm = this;
                this.$store.dispatch('pickup/validate').then(() => {
                    vm.$router.push('/summary');
                }, () => {
                    vm.$swal('Ups..',
                        'Wystąpił błąd',
                        'error')
                });
            }
        },
        cancelPickup: function () {

            this.$swal({
                title: 'Chcesz przerwać proces odbioru?',
                text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, przerywam!'
            }).then(response => {
                if (response.isConfirmed) {
                    this.$store.dispatch('pickup/reset');
                    this.$store.dispatch('complaint/reset');
                    this.$router.push('/');
                }
            })
            //
        },

    },
    created() {
        if (this.$store.state.disloading.order && !this.$store.state.pickup.order) {
            this.$swal('Musisz najpierw zakończyć proces wydawania towaru aby przyjąć towar od klienta!').then(() => {
                this.$router.push('/');
            });
        }
        if (this.$store.state.zaladunek.locationFrom) {
            this.$swal('Musisz najpierw zakończyć proces ładowania towaru aby wydać towar do klienta!').then(() => {
                this.$router.push('/');
            });
        }
        if (!this.$store.state.disloading.order && !this.$store.state.pickup.order) {
            this.$router.push('/pickup/selectorder');
        } else {
            this.$store.dispatch('pickup/validate');
        }
    },
    mounted() {
        this.pojazd_id = this.$store.state.pojazd_id;
        this.location_id = this.$store.state.location_id;
        this.orders = this.$store.state.orders;
        this.loadTowary = this.$store.state.loadTowary;
        // this.calculateInStock();
        // this.countIlosc()
    }
}
</script>

