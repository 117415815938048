<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <StoreCylinderScanner @change="showError = true"></StoreCylinderScanner>
      <button
        @click="cancelLoading"
        class="btn btn-lg btn-secondary m-2"
      >
        <i class="bi bi-x-octagon"></i>
      </button>
      <a
        v-if="$store.state.zaladunek.valid || !showError"
        type="button"
        :disabled="loading"
        @click.prevent="doTakein"
        class="btn btn-lg btn-success flex-fill m-2"
        ><span
          v-if="loading"
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span
        >Przyjmij</a
      >
      <ValidationErrorsModal
        v-if="!$store.state.zaladunek.valid && showError"
        type="button"
      ></ValidationErrorsModal>
    </div>

    <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
      <span class="col-9">Załadunek</span>
      <div class="col-3">
        <span
          class="bg-secondary me-2 ms-0 h-100 badge fs-5 d-flex align-items-center justify-content-center"
          >{{ $store.getters["zaladunek/countAllCylinders"] }}</span
        >
      </div>
    </div>
    <div
      class="w-100 p-2 pb-5 flex-grow-1"
      style="overflow: auto"
    >
      <router-link
        :to="'/loading/item/' + item.product_id"
        v-for="(item, key) in items"
        :key="key"
        :class="
          $store.getters['zaladunek/isLastScannedProduct'](item.product_id)
            ? 'bg-lightgreen'
            : 'bg-light'
        "
        class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center"
      >
        <span class="flex-fill pe-2">{{ item.title }} </span>

        <span
          :class="{
            'bg-danger':
              $store.getters['zaladunek/getErrors']({ product_id: item.product_id }).length > 0,
            'bg-warning':
              $store.getters['zaladunek/getWarnings']({ product_id: item.product_id }).length > 0,
          }"
          class="bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center"
          >{{ $store.getters["zaladunek/countCylinder"](item.product_id) }}
          {{ item._ownLoading ? "" : "/" }} {{ item.quantity }}</span
        >
      </router-link>

      <router-link
        v-if="badCylinders.length > 0"
        :to="'/loading/badcylinder'"
        class="bg-light p-2 text-dark d-flex justify-content-between mb-2 align-items-center"
      >
        <span class="flex-fill pe-2 text-danger">Butle nie pasujące</span>

        <span class="bg-danger col-3 badge fs-5 d-flex align-items-center justify-content-center">
          {{ badCylinders.length }}</span
        >
      </router-link>
    </div>
    Magazyn żródłowy: {{ locationFrom.location_name }}

    <!-- {{ $store.state.zaladunek.cylinders.length }}/ {{ iloscBulti }}< -->
    <router-link
      v-if="$store.state.zaladunek.allowEmpty"
      to="/loading/selectproduct"
      style="
        width: 50px;
        height: 50px;
        background: #dadada;
        border-radius: 5px;
        font-weight: bold;
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 1000;
      "
      class="d-flex justify-content-center align-items-center fs-1 text-dark"
    >
      <i class="bi bi-plus"></i>
    </router-link>
  </div>
</template>
<script>
// import myApi from '@/api/myApi'

import StoreCylinderScanner from "@/components/loading/StoreCylinderScanner.vue";
import api from "@/api/myApi";
import ValidationErrorsModal from "@/components/loading/ValidationErrorsModal.vue";
// import CylinderListElement from '../components/CylinderListElement.vue'

export default {
  name: "LoadingView",
  components: {
    StoreCylinderScanner,
    ValidationErrorsModal,
  },
  props: {
    msg: String,
  },
  data: function () {
    return {
      submitCode: "",
      loading: false,
      location_id: 0,
      orders: [],
      iloscBulti: 0,
      iloscBultiinOrders: 0,
      skantext: "",
      loadTowary: [],
      cylinders: [], //zeskanowowane butle
      inne: [], //produkty ktore sa złe
      pojazd_id: 0,
      listaQrcodowDoOPrzyjecia: [],
      showError: false,
    };
  },
  computed: {
    items: function () {
      console.log(this.$store.getters);
      return this.$store.getters["zaladunek/getProducts"];
    },
    locationFrom: function () {
      return this.$store.state.zaladunek.locationFrom
        ? this.$store.state.zaladunek.locationFrom
        : {};
    },
    badCylinders: function () {
      return this.$store.getters["zaladunek/getBadCylinder"];
    },
  },
  methods: {
    doTakein: function () {
      let warnings = this.$store.state.zaladunek.warnings;
      if (warnings.length > 0) {
        this.$swal({
          title: "Uwaga!",
          html: warnings.length > 0 ? "" + Array.from(warnings, (it) => it.info).join("<br> ") : "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Wróć",
          confirmButtonText: "Przyjmij!",
        }).then(this.doTakeinValidate);
      } else {
        this.doTakeinValidate({ isConfirmed: true });
      }
    },
    doTakeinValidate: function (response) {
      this.showError = true;
      if (!response.isConfirmed) return;
      this.loading = true;
      let vm = this;
      this.$store.dispatch("zaladunek/validate").then(() => {
        let data = vm.$store.getters["zaladunek/getFullData"];
        api.doTakein(vm.location_id, data).then(
          () => {
            vm.$swal("Sukces", "Auto zostało załadowane", "success").then(() => {
              vm.$router.push("/");
            });
            vm.$store.dispatch("zaladunek/syncOrders");
            this.$store.dispatch("zaladunek/reset");
            vm.$store.dispatch("car/syncState");
            vm.loading = false;
          },
          () => {
            vm.loading = false;
            vm.$swal("Ups..", "Wystąpił błąd", "error");
          }
        );
      });
    },

    cancelLoading: function () {
      this.$swal({
        title: "Chcesz przerwać proces załadunku?",
        text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak, przerywam!",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store.dispatch("zaladunek/reset");
          this.$router.push("/");
        }
      });
      //
    },
  },
  created() {
    //this.filterCylinders(); // this.$store.state.cylinders;
    this.$store.dispatch("zaladunek/syncOrders");
    this.$store.dispatch("zaladunek/validate");
    console.log(this.$store.state.zaladunek.location_from_id);
    if (this.$store.state.disloading.order || this.$store.state.pickup.order) {
      this.$swal(
        "Musisz najpierw zakończyć proces wydawania/odbierania towaru aby załadować na auto nowe zamówienia!"
      ).then(() => {
        this.$router.push("/");
      });
    }
    if (!this.$store.state.zaladunek.locationFrom) {
      this.$router.push("/loading/location");
    } else {
      if (
        (!this.items || (this.items && this.items.length == 0)) &&
        this.$store.state.zaladunek.orders.length == 0
      ) {
        this.$swal("Nie masz żadanych zamówień do załadunku!").then(() => {
          this.$store.dispatch("zaladunek/reset");
          this.$router.push("/");
        });
      }
    }
  },
  mounted() {
    this.pojazd_id = this.$store.state.pojazd_id;
    this.location_id = this.$store.state.location_id;
    this.orders = this.$store.state.orders;
    this.loadTowary = this.$store.state.loadTowary;
    // this.calculateInStock();
    // this.countIlosc()
  },
};
</script>
